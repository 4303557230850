<template>
  <div>
    <a-form-model ref="formRef" :model="newData" :rules="rules">
      <a-form-model-item label="Name" prop="nama">
        <a-input
          size="large"
          :value="newData.nama"
          @change="(e) => handleChange(e.target.value, 'nama')"
        />
      </a-form-model-item>
      <a-form-model-item label="Passing Grade" prop="nilai">
        <a-input-number
          size="large"
          v-model="newData.nilai"
          :min="0"
          :max="100"
          @change="(e) => handleChange(e, 'nilai')"
        />
      </a-form-model-item>
      <a-form-model-item
        label="Global Passing Grade"
      >
      <!-- <span slot="label"> Set to Mi&nbsp;</span> -->
      <div class="p-3 border rounded">
        <a-checkbox
          :checked="newData.isTeacherNote"
          @change="(e) => handleChange(e.target.checked, 'isTeacherNote')"
        >
          Set as global passing grade (will be used on Teacher's note template final report)
        </a-checkbox>
      </div>
    </a-form-model-item>
    </a-form-model>
    <a-form-model>
      <a-row v-for="(predicate, i) in intervalPredicates" :key="predicate.key"  :gutter="16">
        <a-col :sm="24" :md="i > 1 ? 4 : 6">
          <a-form-model-item required label="Predicate">
            <a-select size="large" class="w-100" v-model="predicate.id_predikat">
              <a-select-option v-for="pred in listPredicates" :key="pred.id" :value="pred.id">{{pred.nama}}</a-select-option>
              <!-- <a-select-option>tes</a-select-option> -->
            </a-select>
          </a-form-model-item>
        </a-col>
        <a-col :sm="24" :md="9">
          <a-form-model-item required label="Minimum Grade">
            <a-input-number @change="handleChangeGrade(predicate, i)" :disabled="i === intervalPredicates.length - 1" v-model="predicate.batas_bawah" :max="intervalPredicates[i - 1] ? Number(intervalPredicates[i - 1].batas_bawah) : 100" :min="i === intervalPredicates.length - 1 ? 0 : 1" size="large" class="w-100"></a-input-number>
          </a-form-model-item>
        </a-col>
        <a-col :sm="24" :md="9">
          <a-form-model-item required label="Maximum Grade">
            <a-input-number disabled :value="intervalPredicates[i - 1] ? intervalPredicates[i - 1].batas_bawah : i === 0 ? 100 : 0" :min="0" :max="100" size="large" class="w-100"></a-input-number>
          </a-form-model-item>
        </a-col>
        <a-col :md="2" :sm="24" v-if="i > 1">
          <a-form-model-item label=" " :colon="false">
          <div class="w-100">
            <a-button class="w-100 text-danger border border-danger" @click.prevent="deletePredicate(predicate.key)" size="large" icon="delete">
            </a-button>
          </div>
          </a-form-model-item>
        </a-col>
      </a-row>
    </a-form-model>
    <a-button v-if="this.intervalPredicates.length !== this.listPredicates.length" @click.prevent="addNewPredicate" class="mb-4" size="large" block icon="plus">ADD NEW PREDICATE</a-button>
    <!-- <form-interval-predicate v-for="(predicate, i) in predicates" :key="i" :newData="predicate"></form-interval-predicate> -->
  </div>
</template>

<script>
// const FormIntervalPredicate = () => import('./IntervalPredicate')
const rules = {
  nama: [{ required: true, message: 'Name is required!', trigger: 'blur' }],
  nilai: [{ required: true, message: 'Passing grade is required!', trigger: 'blur' }],
}
export default {
  // components: { FormIntervalPredicate },
  props: {
    newData: {
      type: Object,
      required: true,
    },
    listPredicates: {
      type: Array,
      required: true,
    },
    isSubmit: {
      type: Boolean,
    },
    visible: {
      type: Boolean,
    },
  },
  data() {
    return {
      rules,
      intervalPredicates: [],
    }
  },
  methods: {
    handleChange(value, column) {
      const payload = { value, column }
      this.$emit('handle-change', payload)
    },
    handleChangeGrade(newData, i) {
      // const newData = this.intervalPredicates.find(pred => pred.key === data.key)
      console.log(this.intervalPredicates[i + 1], i, newData)
      // if (this.intervalPredicates[i + 1]) {
      //   this.intervalPredicates[i + 1] = {
      //     ...this.intervalPredicates[i + 1],
      //     batas_atas: newData.batas_bawah,
      //   }
      // }
    },
    addNewPredicate() {
      const isEmpty = this.intervalPredicates.find(int => !int.id_predikat || int.batas_bawah === null || int.batas_bawah === undefined)
      if (isEmpty) {
        return this.$notification.error({
          message: 'REQUIRED FIELD',
          description: 'Check on your interval predicate field. All field is required.',
        })
      }
      this.intervalPredicates.push({
        key: this.lastIntervalPredicate.key + 1,
        id_predikat: this.listAvailablePredicates[0].id,
        batas_bawah: 0,
      })
    },
    deletePredicate(key) {
      this.intervalPredicates = this.intervalPredicates.filter(pred => pred.key !== key)
      this.intervalPredicates[this.intervalPredicates.length - 1] = {
        ...this.intervalPredicates[this.intervalPredicates.length - 1],
        batas_bawah: 0,
      }
    },
    initiatePredicate() {
      const predikat1 = {
        key: 1,
        id_predikat: this.listPredicates[0].id,
        batas_bawah: 75,
      }
      const predikat2 = {
        key: 2,
        id_predikat: this.listPredicates[1].id,
        batas_bawah: 0,
      }
      this.intervalPredicates = [predikat1, predikat2]
    },
  },
  watch: {
    isSubmit(newVal) {
      if (newVal) {
        const isEmpty = this.intervalPredicates.find(int => !int.id_predikat || int.batas_bawah === null || int.batas_bawah === undefined)
        const checkInterval = {}
        let isSame = false
        for (let i = 0; i < this.intervalPredicates.length; i++) {
          const int = this.intervalPredicates[i]
          if (!checkInterval[int.id_predikat]) checkInterval[int.id_predikat] = true
          else {
            isSame = true
            break
          }
        }
        if (isSame) {
          this.$emit('handle-validate', { valid: false, message: 'There are same predicate on different interval. Please check again.' })
        } else if (isEmpty) {
          this.$emit('handle-validate', { valid: false, message: 'Check on your interval predicate field. All field is required.' })
          // this.$notification.error({
          //   message: 'REQUIRED FIELD',
          //   description: 'Check on your interval predicate field. All field is required.',
          // })
        } else {
          this.$refs.formRef.validate(valid => {
            this.$emit('handle-validate', { valid, predicates: this.intervalPredicates })
          })
        }
      }
    },
    visible(newVal) {
      if (newVal) {
        if (this.newData.predicates.length) {
          this.intervalPredicates = [...this.newData.predicates]
        } else {
          this.initiatePredicate()
          // this.intervalPredicates = this.listPredicates.map((list, i) => {
          //   let batas_bawah = 0
          //   if (i === 0) batas_bawah = 4
          //   return {
          //     key: i,
          //     id_predikat: list.id,
          //     batas_bawah: 0
          //   }
          // })
        }
      }
    },
  },
  computed: {
    lastIntervalPredicate() { return this.intervalPredicates[this.intervalPredicates.length - 1] },
    listAvailablePredicates() {
      const obj = {}
      this.intervalPredicates.forEach(pred => { obj[pred.id_predikat] = true })
      const data = []
      this.listPredicates.forEach(pred => {
        if (!obj[pred.id]) {
          obj[pred.id] = true
          data.push(pred)
        }
      })

      console.log(data)

      return data
    },
  },
  mounted() {
    if (this.newData.predicates.length) {
      this.intervalPredicates = [...this.newData.predicates]
    } else {
      this.initiatePredicate()
    }
  },
}
</script>

<style>
</style>
